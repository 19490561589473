import '../styles/main.scss';

$(() => {
    /**
     * -------------------------------------------------------------------------
     * Tootip
     * -------------------------------------------------------------------------
     */
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[title]'));
    tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    })

    /**
     * -------------------------------------------------------------------------
     * Links externos
     * -------------------------------------------------------------------------
     *
     * Lembre-se de inserir o ícone em CSS
     */
    $('body').find('a[href*="//"]').each(function() {

        // Não fazer em links dentro do próprio domínio.
        var a = new RegExp('/' + window.location.host + '/')
        if(1 == 0
            || a.test(this.href)
            || $(this).hasClass('skip-external')
        ) { return true; }

        // Adicionar atributo "rel"
        $(this).attr('rel', 'external');
        $(this).attr('title', 'Site externo');

        // Verifica se tem um elemento de texto
        if(1 == 1
            && $(this).find('.text')
            && $(this).find('.text').length > 0
        ) { $(this).addClass('skip-withtext'); }

    });

});
